// custom typefaces
import "@fontsource/castoro/latin-400.css"
// import "@fontsource/inter/latin-400.css"
import "@fontsource/poppins/latin-400.css"
// normalize CSS across browsers
// custom CSS styles
import "./src/styles/styles.css"
// custom JS
// import "uikit/dist/js/uikit.min.js"
export { wrapPageElement } from "./gatsby-shared"
export const onServiceWorkerUpdateReady = () => {
  window.location.reload()
}