import React from "react"
import { Script } from "gatsby"
export const siteinfos = {
  sitename: "Poe-Ma Insurances",
  url:
    process.env.NODE_ENV === "development"
      ? "http://localhost:8000"
      : "https://life.nc",
  description:
    "Votre magazine sur l'actualité artistique et culturelle de la Nouvelle-Calédonie.\nCe site est conçu pour vous tenir informé des lieux incontournables, des événements à ne pas manquer, et pour vous faire découvrir les artistes talentueux de notre caillou.",
  rgpdBanner:
    "En poursuivant votre navigation sur ce site, vous acceptez l’utilisation de cookies pour nous permettre de réaliser des statistiques de visites. voir **[notre politique de confidentialité](/confidentialite)**",
  gacode: "G-XQFDSSGP55",
  gaviagtm: true,
  facebookPixel: "",
  mapboxtoken:
    "pk.eyJ1Ijoia2Fpcm9zLW5jIiwiYSI6ImNrZ2E3eTkyMjA0Z2syeXF1dzVqbjd1Z2oifQ.oal7C84Rew6dLPxaUZFJEA",
  favicon: "/img/favicon-poema-128.png",
  icon: "static/logo/logo.png",
  page404: {
    title: "PAGE 404",
    text: "Désolé, cette page n'existe pas, enfin, la 404 oui, mais pas celle que vous avez appelé...",
  },
  maintenanceContent: "# Nous reviendrons bientôt",
  bugCorrectionIsInMaintenanceMode: true,
}

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      <Script src="https://cdn.jsdelivr.net/npm/uikit@3.21.5/dist/js/uikit.min.js" />
      <Script
        async
        src={"https://www.googletagmanager.com/gtag/js?id=" + siteinfos.gacode}
      />
    </>
  )
}
